import { useToast } from 'vue-toastification';
import { defineStore } from 'pinia';
import type { App } from '@/types/app';
import { generateMAC, toBool } from '@/plugins/utils';
import type { Category, Channel, Credential, Right, UserData } from '@/types';

const workerTv = new Worker(new URL('tv.worker.ts', import.meta.url), {
	type: 'module',
});
const API_BASE_V1 = import.meta.env.VITE_API_V1 as string;
const API_BASE_V2 = import.meta.env.VITE_API_V2 as string;

export const pinia = defineStore('store', {
	state: () => ({
		mini: false as boolean,
		internalIP: '0.0.0.0' as string,
		credential: {} as Credential,
		epgs: [] as Channel[],
		rights: {} as { [key: string]: Right },
		channels: {} as { [key: string]: Channel },
		category: 'favorite',
		categories: [] as [string, Category][],
		userData: {} as UserData,
	}),
	getters: {
		isUserLogged(state): boolean {
			return Boolean(state.credential.session);
		},
		isAdmin(state): boolean {
			const adminFlags =
				state.credential.us_admin_access ?? state.credential.ses_usacl ?? '';

			return adminFlags.includes('epg_admin');
		},
		getRequiredPins(state): { parent: boolean; buy: boolean } | undefined {
			// Return { parent: true, buy: true };
			return state.credential.us_enabled_pins;
		},
		getFamily(state): string {
			return state.credential.ses_family;
		},
		getSession(state): string {
			return state.credential.session;
		},
		getPk(state): string {
			return state.credential.ses_pk;
		},
		getTacombo(state): string {
			return state.credential.ses_taco;
		},
		getFavorites(state): { [key: string]: number } {
			const favorites = state.userData.favorites;
			if (!favorites || Array.isArray(favorites)) {
				return {};
			}

			return favorites;
		},
		getFiltered(state): Channel[] {
			return state.epgs.filter((channel: Channel) => {
				let { category } = state;
				if (category === 'favorite') {
					if (Object.keys(this.getFavorites).length > 0) {
						return this.getFavorites[channel.ch_id];
					}

					category = 'cz';
				}

				return !category || channel.ch_country.includes(category);
			});
		},
		getChannelOrder(state): { [key: string]: number } {
			return state.userData.channel_order || {};
		},
	},
	actions: {
		setCredential(credential: Credential | undefined): void {
			if (credential) {
				localStorage.setItem('session', credential.session);
			} else {
				localStorage.removeItem('session');
			}

			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			this.credential = credential ?? {};
		},
		setChannelsEPG(epgs: Channel[], sort = true): void {
			this.epgs = Object.values(epgs).filter((channel) => {
				const right = this.rights[channel.ch_id];
				if (!right) return true;

				const displayAllowed = toBool(right.ta_display_channels);
				const familyDisplayAllowed = right.ch_allowed_family_display.includes(
					this.credential.ses_family,
				);

				return displayAllowed && familyDisplayAllowed;
			});

			if (sort) {
				this.epgs.sort((a: Channel, b: Channel) => {
					const channelOrder = this.userData.channel_order || {};

					const orderA = Object.hasOwn(channelOrder, a.ch_id)
						? channelOrder[a.ch_id]
						: Number(a.ch_default_order);
					const orderB = Object.hasOwn(channelOrder, b.ch_id)
						? channelOrder[b.ch_id]
						: Number(b.ch_default_order);

					return orderA - orderB;
				});
			}

			this.channels = this.epgs.reduce((result, item) => {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				result[item.ch_id] = item;
				return result;
			}, {});
		},
		setCategories(categories: { [key: string]: Category }): void {
			this.categories = Object.entries(categories).sort(
				(a: [string, Category], b: [string, Category]) => {
					return Number(a[1].dsc_sort) - Number(b[1].dsc_sort);
				},
			);
		},

		async fetchInternalIp(): Promise<void> {
			const controller = new AbortController();
			const timeoutId = setTimeout(() => {
				controller.abort();
			}, 2000);

			const url = new URL(API_BASE_V1);
			url.searchParams.set('function', 'auth/fetch_internal_ip');

			try {
				const response = await fetch(url, {
					signal: controller.signal,
				});
				if (response.ok) this.internalIP = await response.text();
			} catch (error) {
				console.error(error);
			} finally {
				clearTimeout(timeoutId);
			}
		},
		async createSession(auth?: App.Auth): Promise<void> {
			const session = localStorage.getItem('session');

			if (session) {
				await this.checkSession(session);
				if (this.isUserLogged) return;
			}

			const macAddress = localStorage.getItem('stbserial') ?? generateMAC();
			localStorage.setItem('stbserial', macAddress);

			const url = new URL(API_BASE_V1);
			url.searchParams.set('function', 'auth/do_auth');
			url.searchParams.set('client_ip', this.internalIP);
			url.searchParams.set('client_info', navigator.userAgent);
			url.searchParams.set('stbserial', macAddress);
			url.searchParams.set('domain', window.location.hostname);
			url.searchParams.set('p_key', 'dkkDFgDGsQgIRaDA_C_EFR4xR');

			if (auth) {
				url.searchParams.set('user', auth.user);
				url.searchParams.set('password', auth.password);
			}

			const response = await fetch(url);

			const toast = useToast();
			if (response.ok) {
				toast.success('Úspěšně přihlášeno');
				this.setCredential(await response.json());
			} else {
				let message = 'Vyskytla se neznámá chyba.';
				switch (response.status) {
					case 400:
					case 401:
					case 402:
					case 403:
					case 404: {
						message = 'Uživatelské jméno nebo heslo je nesprávné.';
						break;
					}

					case 429: {
						message = 'Byl překročen max. počet současných přihlášení.';
						break;
					}

					default: {
						message = 'Vyskytla se neznámá chyba.';
					}
				}

				if (auth?.user) toast.error(message);
			}
		},
		async checkSession(session: string): Promise<void> {
			const controller = new AbortController();
			const timeoutId = setTimeout(() => {
				controller.abort();
			}, 10_000);

			const url = new URL('/auth/', API_BASE_V2);
			url.searchParams.set('function', 'session_check');
			url.searchParams.set('session', session);

			try {
				const response = await fetch(url, {
					signal: controller.signal,
				});

				if (response.ok) {
					this.setCredential(await response.json());
				} else if (response.status !== 500) {
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					this.setCredential({});
				}
			} catch (error) {
				console.error(error);
			} finally {
				clearTimeout(timeoutId);
			}
		},
		async endSession(): Promise<void> {
			const url = new URL(API_BASE_V1);
			url.searchParams.set('function', 'auth/session_end');
			url.searchParams.set('session', this.getSession);

			await fetch(url);

			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			this.setCredential({});
		},
		fetchChannelsEPG(): void {
			workerTv.postMessage(this.getTacombo);
			workerTv.addEventListener('message', (e: MessageEvent) => {
				this.setChannelsEPG(e.data);
			});
		},
		async fetchCategories(): Promise<void> {
			const url = new URL('/epg/category_names.json', API_BASE_V2);

			const response = await fetch(url);

			if (response.ok) this.setCategories(await response.json());
		},
		async fetchUserData(): Promise<void> {
			const url = new URL('/epg/user/', API_BASE_V2);
			url.searchParams.set('function', 'userdata');
			url.searchParams.set('session', this.getSession);

			const response = await fetch(url);

			if (response.ok) this.userData = await response.json();
		},
		async fetchRights(): Promise<void> {
			const tacombo = this.getTacombo;
			const url = new URL(`/epg/rights/${tacombo}.json.gz`, API_BASE_V2);

			const response = await fetch(url);

			if (response.ok) this.rights = await response.json();
		},
	},
});
